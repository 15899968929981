<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import draggable from 'vuedraggable';

	export default {
		name: "BoInfoCat",
		extends: Base,
		components: {
			draggable
		},
		data() {
			return {
				Name: "BoInfoCat",
				mrLevel: {},
		        metatitleLength: 0,
		        maxmetatitleLength: 60,
		        metadescLength: 0,
		        maxmetadescLength: 160,
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.search'(v){
				this.search()
			},
			'row.aic_seo_title'(v){
				this.metatitleLength = v.length
			},
			'row.aic_seo_desc'(v){
				this.metadescLength = v.length
			}
		},
		
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row gutter-10">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-9 col-lg-3 col-sm-4 ml-auto">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
												placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-3 col-sm-auto">
									<router-link :to="{name:Name}" class="btn btn-warning btn-block">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="aic_name"></SortField>
									</th>
									<th>{{fields.aic_is_active}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<draggable v-model="data.data" tag="tbody" @end="endDrag">
								<tr v-for="(v,k) in data.data" :key="k" style="cursor: move;">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.aic_name}}</td>
									<td>
										<StatusLabel :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action"
											@click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
											@click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</draggable>
						</table>
					</div>
					<Pagination class="justify-content-end mt-3 mb-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" Category"}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-5">
							<BoField name="aic_name" v-model="row.aic_name"></BoField>
						</div>
						<div class="col-7">
							<BoField name="aic_is_active">
								<radio name="aic_is_active" v-model="row.aic_is_active" option="Y"
									:attr="validation('aic_is_active')">Active</radio>
								<radio name="aic_is_active" v-model="row.aic_is_active" option="N">Inactive</radio>
							</BoField>
						</div>
					</div>
					<div class="row">
						<div class="col-12"><hr>
							<h5 class="card-title mb-3">Meta Content</h5>
						</div>
						<div class="col-sm-6">
							<BoField class="mb-0" name="aic_seo_title" :attr="{minlength:'3'}" v-model="row.aic_seo_title" mandatory></BoField>
							<small class="mb-2 d-inline-block text-info">{{maxmetatitleLength-metatitleLength}} character remaining </small> 
							<BoField name="aic_seo_keyword" mandatory class="mb-0">
								<TagsInput name="aic_seo_keyword" v-model="row.aic_seo_keyword"
									:attr="validation('aic_seo_keyword')">
								</TagsInput>
							</BoField>
						</div>
						<div class="col-sm-6">
							<BoField class="mb-0" name="aic_seo_desc" mandatory>
								<textarea name="aic_seo_desc" rows="5" :attr="{minlength:'10'}" v-model="row.aic_seo_desc"
									v-bind="validation('aic_seo_desc')" class="form-control"></textarea>
							</BoField>
							<small class="mb-2 d-inline-block text-info">{{maxmetadescLength-metadescLength}} character remaining </small> 
						</div>
					</div>
					<div class="row">
						
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">{{row.id ? "Update":"Add"}} Health Info Category<i
										class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>